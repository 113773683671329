<template>
  <div class="info-s">
    <Head></Head>
    <div class="img-info">
      <div v-for="(item, index) in img_list" v-bind:key="index">
        <transition
          name="fade"
          enter-active-class="fadeIn"
          leave-active-class="fadeIn"
        >
          <img v-if="flag" :src="item.src" />
        </transition>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "zhengce",
  components: { Head, Foot },
  data() {
    return {
      flag: false,
      img_list: [
        {
          src: require("@/image/zhengce/政策与制度_01.jpg"),
        },
        {
          src: require("@/image/zhengce/政策与制度_02.jpg"),
        },
        {
          src: require("@/image/zhengce/政策与制度_03.jpg"),
        },
        {
          src: require("@/image/zhengce/政策与制度_04.jpg"),
        },
        {
          src: require("@/image/zhengce/政策与制度_05.jpg"),
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.flag = !this.flag;
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.info-s {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.img-info {
  width: 100%;
  height: 100%;
}
</style>
